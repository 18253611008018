import React, { useState, useEffect, useRef, KeyboardEvent } from 'react';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Button from "../../components/Button/Button";
import TextInput from "../../components/Fields/TextInput";
import "./SignUp.css";
import { useLocation, useNavigate } from 'react-router-dom';

const SignUp: React.FC = () => {
  const location = useLocation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [instructionText, setInstructionText] = useState('Verify mobile phone number');
  const [isError, setIsError] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isNavigating, setIsNavigating] = useState(false);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(30);
  const email = location.state?.email || null;
  const password = location.state?.password || null;

  const navigate = useNavigate();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const formatPhoneNumber = (input: string): string => {
    const digits = input.replace(/\D/g, '');
    const match = digits.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (!match) return input;
    const formatted = [
      match[1] ? `(${match[1]}` : '',
      match[2] ? `) ${match[2]}` : '',
      match[3] ? `-${match[3]}` : ''
    ].join('');
    return formatted.trim();
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhoneNumber(e.target.value);
    setPhoneNumber(formatted);
    setIsError(false);
    setInstructionText('Verify mobile phone number');
  };

  const handleRequestCode = () => {
    setIsError(false);
    if (!phoneNumber) {
      setInstructionText('Please enter a valid phone number.');
      setIsError(true);
      return;
    }

    const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'US');
    if (parsedNumber && parsedNumber.isValid()) {
      const formattedNumber = parsedNumber.format('E.164');
      setFormattedPhoneNumber(formattedNumber);
      console.log("Formatted phone number:", formattedNumber);
      sendCodeToPhone(formattedNumber);
    } else {
      setInstructionText('Please enter a valid phone number.');
      setIsError(true);
    }
  };

  const sendCodeToPhone = (phoneNumber: string) => {
    setIsTransitioning(true);
    iframeRef.current?.contentWindow?.postMessage({ phoneNumber }, '*');
    setIsResendDisabled(true);
    setResendCountdown(15);
  };

  const handleVerifyCode = () => {
    setIsError(false);
    if (!verificationCode) {
      setInstructionText('Please enter the verification code.');
      setIsError(true);
      return;
    }
    iframeRef.current?.contentWindow?.postMessage({ verificationCode }, '*');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      isCodeSent ? handleVerifyCode() : handleRequestCode();
    }
  };

  const handleResendCode = () => {
    if (!isResendDisabled) {
      sendCodeToPhone(formattedPhoneNumber);
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    inputRef.current?.focus();
  }, [isCodeSent]);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== 'https://verify-3130-1gsizi.twil.io') return;

      console.log("Received message from iframe:", event.data);

      if (event.data && event.data.status === 'code-sent') {
        setIsTransitioning(true);
        setTimeout(() => {
          setIsCodeSent(true);
          setInstructionText('Enter the verification code sent to your phone.');
          setIsError(false);
          setIsTransitioning(false);
        }, 50);
      }

      if (event.data && event.data.status === 'success') {
        setIsNavigating(true);
      } else if (event.data && event.data.status === 'error') {
        setInstructionText('Incorrect verification code. Please try again.');
        setIsError(true);
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [email, password, formattedPhoneNumber, navigate]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isResendDisabled && resendCountdown > 0) {
      timer = setTimeout(() => {
        setResendCountdown(prevCount => prevCount - 1);
      }, 1000);
    } else if (resendCountdown === 0) {
      setIsResendDisabled(false);
    }
    return () => clearTimeout(timer);
  }, [isResendDisabled, resendCountdown]);

  const handleAnimationEnd = () => {
    if (isNavigating) {
      navigate('/signup/account-type', { state: { email, password, phoneNumber: formattedPhoneNumber } });
    }
  };

  return (
    <div
      className={`account-creation-container ${isNavigating ? 'navigating-out' : ''}`}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className="account-creation-wrapper">
        <div className="video-placeholder">
          <p>Video Message from the Founder</p>
        </div>
        <div className="form-section">
          <h2 className={isTransitioning ? 'fade-out' : 'fade-in'}>
            {isCodeSent ? 'Check your phone' : "First, let's create your account"}
          </h2>
          <p className={`subheader ${isError ? 'error-text' : ''} ${isTransitioning ? 'fade-out' : 'fade-in'}`}>
            {instructionText}
          </p>
          <div className="compact-form">
            <div className="input-wrapper">
              <TextInput
                type={isCodeSent ? "text" : "tel"}
                placeholder={isCodeSent ? "Enter verification code" : "(555) 555-5555"}
                value={isCodeSent ? verificationCode : phoneNumber}
                onChange={(e) => {
                  isCodeSent ? setVerificationCode(e.target.value) : handlePhoneNumberChange(e);
                }}
                onKeyDown={handleKeyDown}
                ref={inputRef}
              />
            </div>
            <Button
              color="Secondary"
              className="verify-button"
              onClick={isCodeSent ? handleVerifyCode : handleRequestCode}
            >
              {isCodeSent ? 'Verify Code' : 'Send Verification Code'}
            </Button>
            {isCodeSent && (
              <p className="resend-link">
                Did you not receive a code?{' '}
                {isResendDisabled ? (
                  <span>Resend in {resendCountdown}s</span>
                ) : (
                  <a href="#" onClick={handleResendCode}>Resend</a>
                )}
              </p>
            )}
            <p className="disclaimer">
              You must have a valid phone number to use QuAIL Technologies services. SMS and data charges may apply.
            </p>
          </div>
        </div>
      </div>
      <iframe
        ref={iframeRef}
        src="https://verify-3130-1gsizi.twil.io/index.html"
        width="0"
        height="0"
        style={{ border: 'none', visibility: 'hidden' }}
      />
    </div>
  );
};

export default SignUp;