import axios from "axios";

const BASE_PATH = "/api";

const RETURNED_ERRORS = ["subscription_exists"];

async function getRequest(path: string, params?: string) {
  let fullPath = `${BASE_PATH}${path}`;
  if (params) {
    fullPath = `${fullPath}?${params}`;
  }

  try {
    const response = await axios.get(fullPath);
    return response.data;
  } catch (err) {
    console.error(err);
    throw new Error(`Unexpected error: ${err}`);
  }
}

async function postRequest(path: string, payload: any) {
  const fullPath = `${BASE_PATH}${path}`;
  try {
    const response = await axios.post(fullPath, payload);
    return response;
  } catch (err) {
    if (RETURNED_ERRORS.includes(err.response.data.message)) {
      return err;
    }
    throw new Error(`Unexpected error: ${err}`);
  }
}

async function patchRequest(path: string, payload: any) {
  const fullPath = `${BASE_PATH}${path}`;
  try {
    const response = await axios.patch(fullPath, payload);
    return response;
  } catch (err) {
    console.error(err);
    throw new Error(`Unexpected error: ${err}`);
  }
}

async function putRequest(path: string, payload: any) {
  const fullPath = `${BASE_PATH}${path}`;
  try {
    const response = await axios.put(fullPath, payload);
    return response;
  } catch (err) {
    console.error(err);
    throw new Error(`Unexpected error: ${err}`);
  }
}

// GET
export const getNews = (pageNumber = 1, pageSize = 20): Promise<any> => {
  const params = new URLSearchParams({
    page: pageNumber.toString(),
    page_size: pageSize.toString(),
  }).toString();
  return getRequest(`/news`, params);
};
export const getNewsSources = (): Promise<any> => getRequest(`/news-sources`);
export const getChats = (): Promise<any> => getRequest(`/fin/chat`);
export const getTasks = (): Promise<any> => getRequest(`/tasks`);
export const getUsage = (): Promise<any> => getRequest(`/advisor/usage`);
export const getPolicy = (policyType): Promise<any> =>
  getRequest(`/policy/${policyType}`);
export const getHelp = (helpType): Promise<any> =>
  getRequest(`/help/${helpType}`);
export const getPortfolios = (params): Promise<any> =>
  getRequest(`/portfolios`, params);
export const getAssignments = (): Promise<any> =>
  getRequest(`/fin/assignments`);
export const getTerms = (policyType): Promise<any> =>
  getRequest(`/user-agreement/${policyType}`);
export const getProfile = (): Promise<any> => getRequest(`/settings/profile`);
export const getProfileDataset = (investorId, datasetId): Promise<any> =>
  getRequest(`/investor/${investorId}/${datasetId}`);
export const getInvestor = (investorId): Promise<any> =>
  getRequest(`/investor/${investorId}`);
export const getInvestors = (pageNumber = 1, pageSize = 20): Promise<any> => {
  const params = new URLSearchParams({
    page: pageNumber.toString(),
    page_size: pageSize.toString(),
  }).toString();
  return getRequest(`/investors`, params);
};
export const getBenchmarkResults = (): Promise<any> =>
  getRequest("/benchmark/results");
export const getDataMappings = (): Promise<any> =>
  getRequest(`/fin/data-mapping`);

// POST
export const saveFeedback = (payload): Promise<any> =>
  postRequest(`/feedback`, payload);
export const postAssignment = (payload): Promise<any> =>
  postRequest(`/fin/assignments`, payload);
export const acceptTerms = (policyType, payload): Promise<any> =>
  postRequest(`/user-agreement/${policyType}`, payload);
export const submitContactForm = (form: string, values: {}): Promise<any> =>
  postRequest("/contact", { form, data: values });
export const disableService = (payload): Promise<any> =>
  postRequest(`/integrations/disable`, payload);
export const authRedtail = (payload): Promise<any> =>
  postRequest(`/auth/redtail`, payload);
export const createLinkedinPost = (payload): Promise<any> =>
  postRequest(`/social/linkedin`, payload);
export const createPlaidLinkToken = async (): Promise<any> => {
  const response = await postRequest(`/auth/plaid/link/`, null);
  return response.data.token;
};
export const authorizeInvestmentAccount = (publicToken) =>
  postRequest(`/auth/plaid/exchange/`, { public_token: publicToken });
export const createUser = (email, password, name, phone) =>
  postRequest(`/auth/user-list`, { email, password, name, phone });
export const postDataMapping = (payload): Promise<any> =>
  postRequest(`/fin/data-mapping`, payload);

// PATCH
export const updateNewsSources = (payload): Promise<any> =>
  patchRequest(`/news-sources`, payload);
export const updateFeedback = (payload): Promise<any> =>
  patchRequest(`/feedback`, payload);
export const patchTask = (taskId, payload): Promise<any> =>
  patchRequest(`/task/${taskId}`, payload);
export const updateProfile = (payload): Promise<any> =>
  patchRequest(`/settings/profile`, payload);
export const updateProfileDataset = (
  investorId,
  datasetId,
  payload
): Promise<any> =>
  patchRequest(`/investor/${investorId}/${datasetId}`, payload);

// PUT
export const putAssignment = (assignmentId, payload): Promise<any> =>
  putRequest(`/fin/assignment/${assignmentId}`, payload);

export const putDataMapping = (mappingId, payload): Promise<any> =>
  putRequest(`/fin/data-mapping/${mappingId}`, payload);
